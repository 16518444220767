.navbar {
  z-index: 1030 !important;

  .logo {
    border: 1px solid #E5E5E5;
    border-top: 0;
    width: 180px;
    margin-left: 1em;
    background-color: white;
    padding: 0.4em;
  }

  .dropdown-item {
    &:hover {
      background: none !important;
    }
  }

  .navbar-start, .navbar-user {
    .nav-link {
      color: white !important;
      font-weight: bold;
    }
  }
}
