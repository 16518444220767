@use '../Utilities/variables' as *;

.pin-input-container {
  min-height: 100px;

  .pin-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 1.5rem;
  }

  .pin-input-filled {
    border-color: $primary;
  }
}
