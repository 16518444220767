@use '../Utilities/variables' as *;

.limit-wrapper {
  display: flex;
  align-items: center;
  column-gap: .5em;

  .total-count {
    color: $primary-median-light;
  }
}
