@import "./Utilities/variables";

// Should not have to do that, find why theming doesn't work
.bg-primary {
  background-color: $primary !important;
}

// Should be already included in bootstrap
.cursor-pointer {
  cursor: pointer;
}

.nav-item {
  cursor: pointer;
}

.card {
  border: 2px solid $primary-lighter !important;
  background-color: hsl(0deg, 0%, 100%);
  box-shadow: 0 0 0.5em 0 rgba($primary-lighter, 0.7), 0 0 0 1px rgba($primary-lighter, 0.7) !important;
  color: hsl(0deg, 0%, 29%);
  max-width: 100%;

  .card-header {
    padding: 0.5rem 1rem;
    border: none;
    box-shadow: none;
    color: $link;
    text-transform: uppercase;
    font-size: 0.875em;
    background: $primary-lightest !important;
    border-bottom: 1px solid $primary-lighter !important;

    .card-title {
      text-transform: none;
      color: $primary-darkest;
    }
  }
}

.is-transparent {
  background-color: transparent;
  border-color: transparent;
}

// Tmp, should be already in bootstrap
.float-right {
  float: right;
}

// Tmp, should be already in bootstrap
.font-italic {
  font-style: italic;
}

// Tmp, should be already in bootstrap
.text-underline {
  text-decoration: underline;
}

.pagination {
  .page-item {
    &:not(:last-child) {
      margin-right: 5px;
    }

    .page-link {
      border-radius: 2px;
      line-height: 1em;
    }
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: $primary-lightest;
  --bs-table-accent-bg: none;
}

.table th {
  height: 4em;
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 500;
}

.modal-header {
  border: none;
  padding: 0.5rem 0.7rem 0 0.7rem;
}

a {
  text-decoration: none;
}

.btn-info, .btn-info:disabled, .btn-info.disabled,
.btn-success, .btn-success:disabled, .btn-success.disabled {
  color: white;
}

.input-group {
  .input-group-text {
    font-size: .875rem;
    font-weight: 500;
    border: 2px solid $primary-median;
    border-right: none;
    border-radius: 3px 0 0 3px;
  }

  .form-control {
    font-size: .875rem;
    background-color: $white;
    border: 2px solid $primary-median;
    border-left: none;
  }

  &.light {
    .input-group-text {
      border-width: 1px;
      color: $primary-median;
      background-color: $primary-lightest;
    }

    .form-control {
      border-width: 1px;
    }
  }
}

// Set the "not allowed" cursor for all disabled bootstrap inputs
.form-control:disabled {
  cursor: not-allowed;
}

// Search Dropdown
.dropdown.search-dropdown {
  min-width: 200px;

  .dropdown-toggle.btn-primary {
    border: none;

    &:after {
      display: none;
    }

    &:active, &:hover, &:focus {
      background-color: $primary;
      border: none;
      box-shadow: none;
    }

    .input-group {
      span {
        border-color: $primary-light;
      }

      input {
        border-color: $primary-light;
        border-radius: 0 3px 3px 0;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  span.dropdown-item {
    &:hover {
      background-color: white;
    }
  }
}

.dropdown.search-dropdown {
  z-index: 1000;

  &.show {
    .dropdown-toggle.btn-primary {
      background: none;
      border: none;
    }
  }

  &.expanded {
    position: absolute;
    width: 70%;
    right: 10px;

    .dropdown-toggle {
      width: 100%;
      background: none !important;
      padding: 0;
    }

    .search {
      width: 100%;
    }

    .search-results {
      width: 100%;
    }
  }
}

/**
  All above should be by default in bootstrap. Get rid of them when completly switching to boostrap
 */

.radio {
  height: 30px;
  width: 20px;
}

// Accordion
.accordion {
  .accordion-header {
    .accordion-button {
      padding: 0.75rem 1rem;
      &:focus {
        box-shadow: none;
        border: none;
      }
    }
  }
}

.form-switch .form-check-label {
  margin-left: 1em;
}
