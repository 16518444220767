@use '../../../../../../Style/Utilities/variables' as *;

.form-filler-field-consent {
  .accordion-header {
    height:3em;
    max-height: 55px;
    padding: 0;
    border-radius: 8px;
    background-color: var(--bs-accordion-active-bg);
  }

  .accordion {
    border-radius: 8px;
  }

  .accordion .card  .card-header{
    text-transform: none;
    font-size: 1rem;
    color: #0D6189;
  }

  .accordion .fa-angle-down, .accordion .fa-angle-up {
    color: #71C8F1
  }

  .accordion .was-validated .form-check-input:valid, .form-check .is-valid {
    background-color: #198754;
  }
}
