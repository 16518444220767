@use '../Utilities/variables' as *;

.blue-card {
  border-radius: 5px;
  padding: 0.5em;
  background-color: rgba($body-bg, 0.5);
  border: 1px solid rgba(74,83,97,0.3);
}

form.was-validated .blue-card.is-invalid {
  border: 1px solid $danger;
  background-color: rgba($danger, 0.02);
}
