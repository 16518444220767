@use '../Utilities/variables' as *;

.table {
  thead {
    border-bottom: 2px solid $dark-gray;

    th {
      line-height: normal;

      i {
        background: $primary-lightest;
        padding: 3px 5px;
        border-radius: 2px;

        &:before {
          color: $primary-median;
          font-weight: bold !important;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) > td {
        background: $primary-lightest !important;
      }

      & > td:not(:first-child) {
        border-left: 1px solid $middle-gray;
      }

      & > td {
        line-height: normal;
        border-bottom: 1px solid $middle-gray;
      }

      &:hover > td {
        background: $primary-lighter !important;
      }
    }
  }

  .column-filters {
    .filter-count {
      position: absolute;
      top: -12px;
      right: -14px;
      background: $primary-median;
      color: $white;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 2px;
    }
  }

  .table-actions {
    display: flex;
    gap: 0.5rem;
  }
}

.table-size-select {
  display: flex;
  align-items: center;
  column-gap: .5em;

  .total-items-count {
    color: $primary-median-light;
  }
}

.table-filters-popover {
  min-width: 400px !important;

  .popover-body {
    padding: 0;

    .filter-row {
      display: flex;
      gap: 8px;
      border-bottom: 1px solid $primary-lighter;
      padding: 10px;

      .form-select {
        width: auto;
      }

      .next-filter-select {
        background-color: $primary-lightest;
        color: $primary-median;
        border: none;
      }

      .remove-filter {
        color: $bordeaux;
        border: 1px solid $red;
        border-radius: 3px;
        padding: 5px 9px;

        &:hover {
          color: $white;
          background: $red;
        }
      }
    }

    .btn {
      width: 70px !important;
    }
  }
}
