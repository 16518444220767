@use '../../Utilities/variables' as *;

.text-field {
  &[value=""] {
    background-color: rgba($primary-lightest, .3);
  }

  &.valid {
    border-color: $green !important;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($green, .3)
    }
  }

  &.invalid {
    border-color: $red !important;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($red, .3)
    }
  }
}
