@use '../Utilities/variables' as *;

.instance-card {
  cursor: pointer;
  padding: 0.25em 0.5em;
  margin: 0.5em 0;
  border-radius: 3px;
  border: 2px solid $primary-lighter;
  background-color: $white;
  box-sizing: border-box;
  font-size: .8125em;
  color: rgba($primary-darkest, .61);

  .value {
    color: $primary-darkest;
    font-weight: 500;
  }

  &:hover {
    border-color: $primary-median-light;
  }

  &.active {
    color: $primary-median;
    background-color: $primary-lightest;
    border-color: $primary-median;

    .value {
      color: $primary-median;
    }
  }
}
