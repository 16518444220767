@use '../Utilities/variables' as *;

#patientToolbar {
  position: sticky;
  z-index: 1000;
  padding: 1em 0;
  border-radius: $border-radius;
}

.dropdown-menu.show {
  z-index: 1030;
}
