@use '../Utilities/variables' as *;

.hover-emphasis {
  transition: color 0.1s ease-in-out;

  &:hover {
    color: $primary;
  }
}

.cursor-drag {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.cursor-drag:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.cursor-no-drop {
  cursor: no-drop;

  &:hover {
    cursor: no-drop;
  }
  &:active {
    cursor: no-drop;
  }
}
