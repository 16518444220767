@import '../Utilities/variables';

.calendar-sidebar.card {
  min-width: 380px;

  .calendar-sidebar-tab {
    padding: 4px 4px 2px 4px;
    color: #2d2d2d;//$dark;
    border-radius: 0;
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 0;
  }

  .fc-day {
    padding: 0 4px;

    .fc-daygrid-day-top {
      display: block;
      text-align: center;
      cursor: pointer;
      max-height: 24px;

      .fc-daygrid-day-number {
        padding: 0;
      }

      .day-events {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        height: 35px;

        .fc-dot-event {
          width: 0.35em;
          height: 0.35em;
          border-radius: 50%;
        }

        .fc-more {
          margin: 0;
          font-size: .55em;
          color: var(--red);
        }
      }
    }

    // This events block is rendered by the Fullcalendar and should be hidden
    .fc-daygrid-day-events {
      display: none;
    }
  }
}

// Multiple days event clas, can't get rid of it
.fc-h-event, .fc-v-event {
  background: none !important;
  border: none !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
  box-shadow: none !important;
}
