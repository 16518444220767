@use '../Utilities/variables' as *;

.workflow-create {
  .dropdown-toggle::after {
    display: none;
  }
}

.workflow-manager {
  .workflow-filters {
    margin-bottom: 24px;
  }

  .workflow-columns {
    display: flex;
    justify-content: space-around;

    .workflow-column {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .heading {
        text-transform: uppercase;
        text-align: center;
      }

      .workflow-node {
        position: relative;
        background-color: lighten($primary, 30);
        color: $white;
        padding: 10px 40px;
        border-radius: 4px;
        cursor: default;
        width: 300px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.selectable {
          background-color: lighten($primary, 20);
          border: 1px solid $gray-600;
          cursor: pointer;
        }

        &.selected {
          background-color: $primary;
        }

        .edit {
          cursor: pointer;
          position: absolute;
          top: 4px;
          right: 4px;

          &:hover {
            color: $primary-median;
          }
        }
      }
    }
  }
}

