$primary: #1e4dad;
$secondary: #4D4D4D;
$success: #008A64;
$warning: #FF8800;
$info: #5bc0de;
$danger: #A31535;
$light: #f7f7f7;
$link: #0d6189;
$dark: #2d2d2d;
$radius: 5px;
$radius-small: 4px;

$primary-lightest: #EFF4FE;
$primary-lighter: #D1E0FF;
$primary-light: lighten($primary, 20%);

$primary-median: #2E62E9;
$primary-median-light: #96B3FC;

$primary-dark: darken($primary, 40%);
$primary-darker: darken($primary, 60%);
$primary-darkest: #1B1D3A;

$white: #FFFFFF;
$green: #048636;
$red: #DA1722;
$gray: #919BA6;
$middle-gray: #CBD2D8;
$dark-gray: #2F3439;
$bordeaux: #520000;


$size-1: 3em;
$size-2: 2.5em;
$size-3: 2em;
$size-4: 1.5em;
$size-5: 1.25em;
$size-6: 1em;
$size-7: 0.75em;
$size-8: 0.5em;

$gray-100: #F8F8F9;
$gray-200: #E6EBF0;
$gray-400: #CBD2DF;
$gray-500: #919BA6;
$gray-600: #62676C;
$gray-800: #2F3439;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xl: 1400px;

$body-bg:                     #F6F9FC;
$border-width:                1px !default;

$info-invert: white;

$font-weight-semibold: 500 !default;

$z-index-max: 10000;

// scss-docs-start border-radius-variables
$border-radius:                         .375rem !default;
$border-radius-sm:                      .25rem !default;
$border-radius-lg:                      .5rem !default;

$input-btn-border-width:                $border-width !default;

$input-bg:                              $white;
$input-border-color:                    $primary-median-light;
$input-border-width:                    2px;
$input-border-radius:                   $border-radius;
$input-border-radius-sm:                $border-radius-sm;
$input-border-radius-lg:                $border-radius-lg;

$input-disabled-border-color:           $gray-400;
$input-disabled-color:                  $gray-800;
$input-disabled-bg:                     $gray-100;

$input-group-addon-bg:                  $primary-median;
$input-group-addon-color:               $white;

$form-select-bg:                        $white;
$form-select-disabled-bg:               $gray-100;

$form-label-font-size:                  .875rem;
$form-label-color:                      $primary-darkest;

$form-feedback-icon-invalid-color:      $danger;
$form-feedback-icon-invalid:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-check-input-checked-bg-color:     $primary-median;
$form-check-input-border:               1px solid $primary-darkest;

$table-cell-padding-y:                  1.25rem;
$table-cell-padding-x:                  1.25rem;
$table-cell-padding-y-sm:               .625rem;
$table-cell-padding-x-sm:               .625rem;

$pagination-color:                      $primary-median;
$pagination-active-bg:                  $primary-median;
$pagination-active-color:               $white;
$pagination-border-width:               2px;
$pagination-border-color:               $primary-lighter;
$pagination-padding-y:                  .2rem;
$pagination-padding-x:                  .45rem;

$input-group-addon-color:               $white;
$input-group-addon-bg:                  $primary-median;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $red,
  "light": $light,
  "dark": $dark
);

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "800": $gray-800,
);

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: $primary;
  --secondary: $secondary;
  --success: $success;
  --info: $info;
  --warning: $warning;
  --danger: $danger;
  --light: $light;
  --dark: $dark;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --bs-font-sans-serif: "Ubuntu", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-sans-serif: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

$form-validation-states: (
  "invalid": (
    "color": $form-feedback-icon-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
);
