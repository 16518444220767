@charset "utf-8";

// Custom components style
@forward './Components';
@forward './Views';

/*
  sass should make load modules available for import by adding : "--load-path=node_modules"
  But it is adding .cache sub-folder so it's doing a "System limit for number of file watchers reached" error
 */
@import './Utilities';

@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.scss';

// Syncfusion
@import '@syncfusion/ej2-base/styles/bootstrap5.css';
@import '@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '@syncfusion/ej2-react-documenteditor/styles/bootstrap5.css';

// Fonts
@import '@fortawesome/fontawesome-free/css/all.css';

// Datepicker
@import 'react-datepicker/dist/react-datepicker.css';

// Custom uppy overrides
@import './uppy-customs';
// Custom bootstrap overrides
@import './bootstrap-customs';
// Custom Font Awesome overrides
@import './fa-customs';

html {
  font-size: 16px;
}

body {
  font-family: "Ubuntu", sans-serif;

  #root {
    display: flex;
    flex-direction: column;
    min-width: 768px;
    min-height: 100vh;

    #appView {

      #viewBody {
        padding: 0 20px 0 20px;
        flex-grow: 1;
      }
    }
  }
}

.text-elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media print {
  body.print-content {
    font-size: 0.5em;

    #appView {
      margin-top: 0;
      padding: 0 20px 0 20px;
      overflow: auto;
      flex-grow: 1;
      width: 100%;
    }

    #appNavbar {
      display: none;
    }

    .form-header-buttons {
      display: none;
    }

    .sidebar-sections {
      display: none;
    }

    #patientDetails {
      display: none;
    }

    .patient-header {
      display: none;
    }

    .patient-sidebar {
      display: none;
    }
  }
}

#columnFieldSelector {
  z-index: 200;
}

.react-datepicker-popper {
  z-index: $z-index-max;
}

.react-datepicker-wrapper {
  width: 100%;
}


